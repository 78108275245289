self.loadPage = (url, token) => {
  let request = new XMLHttpRequest();
  request.open("GET", url);
  request.setRequestHeader("Accept", "application/json");
  request.setRequestHeader("Authorization", `Bearer ${token}`);

  request.send();

  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      if (request.status === 200) {
        const response = JSON.parse(request.response);
        self.postMessage(response);
        console.info(response);
      }
      else {
        self.postMessage("Request status " + request.statusText)
      }
    }
  };
};

self.addEventListener("message", (event) => {
  if (event.data && typeof event.data === 'object' && "url" in event.data) {
    const data = event.data;
    self.loadPage(data.url, data.token);
  }
});
